import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl, intlShape, defineMessages } from 'react-intl';
import VM from 'scratch-vm';

import spriteLibraryContent from '../lib/libraries/sprites.json';
import randomizeSpritePosition from '../lib/randomize-sprite-position';
import spriteTags from '../lib/libraries/sprite-tags';

import LibraryComponent from '../components/library/library.jsx';
import { costumeUpload, spriteUpload } from '../lib/file-uploader.js';

const messages = defineMessages({
    libraryTitle: {
        defaultMessage: 'Choose a Character',
        description: 'Heading for the sprite library',
        id: 'gui.spriteLibrary.chooseASprite'
    }
});

export function extractExtenion(value) {
    let new_value = value.replace("/get", "")
    new_value = new_value.replace("https://cdn.assets.scratch.mit.edu/internalapi/asset/", "")
    new_value = new_value.replace("https://nehanaikdhure-educobot.github.io/open-editor/characters/", "")
    new_value = new_value.replace("https://nehanaikdhure-educobot.github.io/open-editor/new_sprites/", "")
    new_value = new_value.replace("https://nehanaikdhure-educobot.github.io/open-editor/frames/", "")
    console.log(new_value);
    new_value = new_value.split(".")[1]
    if (new_value.includes("png")) {
        new_value = "image/png"
    } else if (new_value.includes("jpg")) {
        new_value = "image/jpg"
    } else if (new_value.includes("jpeg")) {
        new_value = "image/jpeg"
    } else if (new_value.includes("svg")) {
        new_value = "image/svg+xml"
    }
    return new_value
}
export function extractFileName(value) {
    let new_value = value.replace("/get", "")
    new_value = new_value.replace("https://cdn.assets.scratch.mit.edu/internalapi/asset/", "")
    new_value = new_value.replace("https://nehanaikdhure-educobot.github.io/open-editor/characters/", "")
    new_value = new_value.replace("https://nehanaikdhure-educobot.github.io/open-editor/new_sprites/", "")
    new_value = new_value.replace("https://nehanaikdhure-educobot.github.io/open-editor/frames/", "")
    console.log(new_value);
    return new_value
}



class SpriteLibrary extends React.PureComponent {
    constructor(props) {
        super(props);
        bindAll(this, [
            'handleItemSelect'
        ]);
    }
    handleNewSprite(spriteJSONString) {
        return this.props.vm.addSprite(spriteJSONString)

    }
    handleNewCostume(costume, fromCostumeLibrary, targetId) {
        const costumes = Array.isArray(costume) ? costume : [costume];

        return Promise.all(costumes.map(c => {
            // console.log(c);
            return this.props.vm.addCostumeFromLibrary(c.md5, c);
        }));
    }
    async handleItemSelect(item) {
        // Randomize position of library sprite
        // const storage = this.props.vm.runtime.storage;
        // console.log("here it is selectd", item);
        // const response = await fetch(item.costumes[0].md5ext)
        // const result = await response.arrayBuffer()
        // console.log("result", result);
        // console.log(extractExtenion(item.costumes[0].md5ext));
        // spriteUpload(result, extractExtenion(item.costumes[0].md5ext), item.name, storage, newSprite => {
        //     console.log(newSprite);
        //     this.handleNewSprite(newSprite)
        //         .then(() => {
        //             // if (fileIndex === fileCount - 1) {
        //                 //     this.props.onCloseImporting();
        //                 // }
        //         })
        //     },);
        if (item.costumes[0].md5ext.includes("cdn.assets.scratch")) {
            let newCostumes = []
            for (let i = 0; i < item.costumes.length; i++) {
                newCostumes.push({
                    ...item.costumes[i],
                    md5ext: item.costumes[i].md5ext.replace("/get", "").replace("https://cdn.assets.scratch.mit.edu/internalapi/asset/", "")
                })

            }
            item.costumes = newCostumes
            randomizeSpritePosition(item);
            console.log(item);
            this.props.vm.addSprite(JSON.stringify(item)).then(() => {
                this.props.onActivateBlocksTab();
            });

        } else {
            const storage = this.props.vm.runtime.storage;
            for (let i = 0; i < item.costumes.length; i++) {
                if (i === 0) {

                    const response = await fetch(item.costumes[i].md5ext)
                    const result = await response.arrayBuffer()
                    console.log(extractExtenion(item.costumes[i].md5ext));
                    spriteUpload(result, extractExtenion(item.costumes[i].md5ext), item.name, storage, newSprite => {
                        console.log(newSprite);
                        this.handleNewSprite(newSprite)
                            .then(() => {
                                // if (fileIndex === fileCount - 1) {
                                //     this.props.onCloseImporting();
                                // }
                            })
                    },);
                } else {
                    const response = await fetch(item.costumes[i].md5ext, {
                        method: "GET"
                    })
                    const result = await response.arrayBuffer()
                    // console.log("result", result);
                    const storage = this.props.vm.runtime.storage;
                    const targetId = this.props.vm.editingTarget.id;
                    const fileName = item.costumes[i].name;
                    costumeUpload(result, "image/svg+xml", storage, vmCostumes => {
                        // console.log("vmCostumes", vmCostumes);
                        vmCostumes.forEach((costume, i) => {
                            costume.name = `${fileName}${i ? i + 1 : ''}`;
                        });
                        this.handleNewCostume(vmCostumes, true, targetId).then(() => {
                        });
                    });
        
                }
            }
        }



    }
    render() {
        return (
            <LibraryComponent
                data={spriteLibraryContent}
                id="spriteLibrary"
                tags={spriteTags}
                title={this.props.intl.formatMessage(messages.libraryTitle)}
                onItemSelected={this.handleItemSelect}
                onRequestClose={this.props.onRequestClose}
            />
        );
    }
}

SpriteLibrary.propTypes = {
    intl: intlShape.isRequired,
    onActivateBlocksTab: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func,
    vm: PropTypes.instanceOf(VM).isRequired
};

export default injectIntl(SpriteLibrary);
