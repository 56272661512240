// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #264653 */ /* #3373CC */ /* #855CD6 */ /* 35% transparent version of looks-tertiary */ /* 15% transparent version of looks-tertiary */ /* #CAA6A6 */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #264653 */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #264653 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ .dial_container_3hox- {\n    padding: 1rem;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n} .dial_dial-container_ktOnA {\n    position: relative;\n} .dial_dial-face_3NuSA, .dial_dial-handle_1tfZT, .dial_gauge_3g5NZ {\n    position: absolute;\n    top: 0;\n    left: 0;\n    overflow: visible;\n} .dial_dial-face_3NuSA {\n    width: 100%;\n} .dial_dial-handle_1tfZT {\n    cursor: pointer;\n    width: 40px;\n    height: 40px;\n    /* Use margin to make positioning via top/left easier */\n    margin-left: calc(40px / -2);\n    margin-top: calc(40px / -2);\n} .dial_gauge-path_1WoJ6 {\n    fill: hsla(260, 60%, 60%, 0.35);\n    stroke: #264653;\n    stroke-width: 1px;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "dial_container_3hox-",
	"dial-container": "dial_dial-container_ktOnA",
	"dialContainer": "dial_dial-container_ktOnA",
	"dial-face": "dial_dial-face_3NuSA",
	"dialFace": "dial_dial-face_3NuSA",
	"dial-handle": "dial_dial-handle_1tfZT",
	"dialHandle": "dial_dial-handle_1tfZT",
	"gauge": "dial_gauge_3g5NZ",
	"gauge-path": "dial_gauge-path_1WoJ6",
	"gaugePath": "dial_gauge-path_1WoJ6"
};
module.exports = exports;
