import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import VM from 'scratch-vm';
import AudioEngine from 'scratch-audio';

import LibraryComponent from '../components/library/library.jsx';

import soundIcon from '../components/library-item/lib-icon--sound.svg';
import soundIconRtl from '../components/library-item/lib-icon--sound-rtl.svg';

import soundLibraryContent from '../lib/libraries/sounds.json';
import soundTags from '../lib/libraries/sound-tags';

import { connect } from 'react-redux';
import { soundUpload } from '../lib/file-uploader.js';

const messages = defineMessages({
    libraryTitle: {
        defaultMessage: 'Choose a Sound',
        description: 'Heading for the sound library',
        id: 'gui.soundLibrary.chooseASound'
    }
});

class SoundLibrary extends React.PureComponent {
    constructor(props) {
        super(props);
        bindAll(this, [
            'handleItemSelected',
            'handleItemMouseEnter',
            'handleItemMouseLeave',
            'onStop',
            'setStopHandler'
        ]);

        /**
         * AudioEngine that will decode and play sounds for us.
         * @type {AudioEngine}
         */
        this.audioEngine = null;
        /**
         * A promise for the sound queued to play as soon as it loads and
         * decodes.
         * @type {Promise<SoundPlayer>}
         */
        this.playingSoundPromise = null;

        /**
         * function to call when the sound ends
         */
        this.handleStop = null;
    }
    componentDidMount() {
        this.audioEngine = new AudioEngine();
        this.playingSoundPromise = null;
    }
    componentWillUnmount() {
        this.stopPlayingSound();
    }
    onStop() {
        if (this.playingSoundPromise !== null) {
            this.playingSoundPromise.then(soundPlayer =>
                soundPlayer && soundPlayer.removeListener('stop', this.onStop));
            if (this.handleStop) this.handleStop();
        }

    }
    setStopHandler(func) {
        this.handleStop = func;
    }
    stopPlayingSound() {
        if (this.playingSoundPromise !== null) {
            this.playingSoundPromise.then(soundPlayer =>
                soundPlayer && soundPlayer.removeListener('stop', this.onStop));
            if (this.playingSoundPromise.isPlaying) {
                this.playingSoundPromise.then(soundPlayer => {
                    soundPlayer.stop();
                });
            } else {
                this.playingSoundPromise.then(soundPlayer => {
                    if (soundPlayer) soundPlayer.stopImmediately();
                });
            }
            this.playingSoundPromise = null;
        }
    }
    async handleItemMouseEnter(soundItem) {
        const md5ext = soundItem._md5;
        // console.log("soundItem", soundItem);

        if (md5ext.includes("nehanaikdhure-educobot.github.io")) {
            const response = await fetch(md5ext, {
                method: "GET"
            })
            const result = await response.arrayBuffer()
            // console.log("result", result);
            const soundAsset = {
                assedId: md5ext,
                assetType: {
                    "contentType": "audio/mp3",
                    "name": "Sound",
                    "runtimeFormat": "mp3",
                    "immutable": true,
                },
                data: result,
                clean: true,
                dataFormat: "mp3"
            }
            // console.log('soundAsset', soundAsset);
            const sound = {
                md5: "fa5f7fea601e9368dd68449d9a54c995",
                name: soundItem.name,
                format: soundItem.format,
                data: new Uint8Array(soundAsset.data)
            };
            return this.audioEngine.decodeSoundPlayer(sound)
                .then(soundPlayer => {

                    soundPlayer.connect(this.audioEngine);
                    // Play the sound. Playing the sound will always come before a
                    // paired stop if the sound must stop early.
                    soundPlayer.play();
                    soundPlayer.addListener('stop', this.onStop);
                    // Set that the sound is playing. This affects the type of stop
                    // instruction given if the sound must stop early.
                    if (this.playingSoundPromise !== null) {
                        this.playingSoundPromise.isPlaying = true;
                    }
                    return soundPlayer;
                });

        } else {
            const idParts = md5ext.split('.');
            const md5 = idParts[0];
            const vm = this.props.vm;

            // In case enter is called twice without a corresponding leave
            // inbetween, stop the last playback before queueing a new sound.
            this.stopPlayingSound();

            // Save the promise so code to stop the sound may queue the stop
            // instruction after the play instruction.
            this.playingSoundPromise = vm.runtime.storage.load(vm.runtime.storage.AssetType.Sound, md5)
                .then(soundAsset => {

                    if (soundAsset) {
                        const sound = {
                            md5: md5ext,
                            name: soundItem.name,
                            format: soundItem.format,
                            data: soundAsset.data
                        };
                        // console.log('sound', sound);
                        return this.audioEngine.decodeSoundPlayer(sound)
                            .then(soundPlayer => {
                                // console.log("soundPlayer", soundPlayer);
                                soundPlayer.connect(this.audioEngine);
                                // Play the sound. Playing the sound will always come before a
                                // paired stop if the sound must stop early.
                                soundPlayer.play();
                                soundPlayer.addListener('stop', this.onStop);
                                // Set that the sound is playing. This affects the type of stop
                                // instruction given if the sound must stop early.
                                if (this.playingSoundPromise !== null) {
                                    this.playingSoundPromise.isPlaying = true;
                                }
                                return soundPlayer;
                            });
                    }
                });
        }

    }
    handleItemMouseLeave() {
        this.stopPlayingSound();
    }
    async handleItemSelected(soundItem) {
        const vmSound = {
            format: soundItem.format,
            md5: soundItem._md5,
            rate: soundItem.rate,
            sampleCount: soundItem.sampleCount,
            name: soundItem.name
        };
        // console.log("vmSound", vmSound);
        if(vmSound.md5.includes("nehanaikdhure-educobot.github.io")) {
            const response = await fetch(vmSound.md5, {
                method: "GET"
            })
            const result = await response.arrayBuffer()
            // console.log("result", result);
            const storage = this.props.vm.runtime.storage;
            const targetId = this.props.vm.editingTarget.id;
                soundUpload(result, "audio/mpeg", storage, newSound => {
                    newSound.name = soundItem.name;
                    this.props.vm.addSound(newSound, targetId).then(() => {
                        this.props.onNewSound();
                    });
                });
        }else{
            this.props.vm.addSound(vmSound).then(() => {
                this.props.onNewSound();
            });
        }
    }
    render() {
        // @todo need to use this hack to avoid library using md5 for image
        const soundLibraryThumbnailData = soundLibraryContent.map(sound => {
            const {
                md5ext,
                ...otherData
            } = sound;
            return {
                _md5: md5ext,
                rawURL: this.props.isRtl ? soundIconRtl : soundIcon,
                ...otherData
            };
        });

        return (
            <LibraryComponent
                showPlayButton
                data={soundLibraryThumbnailData}
                id="soundLibrary"
                setStopHandler={this.setStopHandler}
                tags={soundTags}
                title={this.props.intl.formatMessage(messages.libraryTitle)}
                onItemMouseEnter={this.handleItemMouseEnter}
                onItemMouseLeave={this.handleItemMouseLeave}
                onItemSelected={this.handleItemSelected}
                onRequestClose={this.props.onRequestClose}
            />
        );
    }
}

SoundLibrary.propTypes = {
    intl: intlShape.isRequired,
    isRtl: PropTypes.bool,
    onNewSound: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func,
    vm: PropTypes.instanceOf(VM).isRequired
};

const mapStateToProps = state => ({
    isRtl: state.locales.isRtl
});

const mapDispatchToProps = () => ({});

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(SoundLibrary));
