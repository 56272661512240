import React from 'react'
import ContentDialogBox from './ContentDialogBox'

function NotificationMessages({
    isVisible,
    title,
    image,
    description
}) {
    if (!isVisible) return null
    return (
        <div>
            <ContentDialogBox
                isOpen={true}
                onClose={() => { }}
                title={title}
                transitionDirection='down'
                isTransition={true}
                content={<>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                        maxHeight: "30vh",
                    }}>
                        <img src={image} style={{ width: "200px" }} alt="" />
                        <p>
                            {description}
                        </p>
                    </div>
                </>}
            />
        </div>
    )
}

export default NotificationMessages