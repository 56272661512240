// import ReactS3Client from 'react-aws-s3-typescript';
import S3 from 'aws-sdk/clients/s3';
import AWS from 'aws-sdk'; // Import entire SDK (optional)


const AWS_BUCKET_NAME = 'open-editor-assets'
const AWS_REGION = 'ap-south-1'
const AWS_ACCESS_ID = "AKIATV35CQSBHKHM7CNB"
const AWS_SECRET_ACCESS_ID = "v16XDQ5aK06R1uwTDeRCsg62gA+p1p3qeBuWq3EG"
const AWS_DIRECTORY = 'teacher-playgrounds'
const S3_BUCKET = "open-editor-assets"; 
const REGION = "ap-south-1"; 
AWS.config.update({
    accessKeyId: "AKIATV35CQSBHKHM7CNB",
    secretAccessKey: "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",
});


export async function uploadFileOnS3(file, filename) {

    const s3 = new S3({
        params: { Bucket: S3_BUCKET },
        region: REGION,
    });
    const params = {
        Bucket: S3_BUCKET,
        Key: filename,
        Body: file,
    };
    try {
        const upload = await s3.putObject(params).promise();
        console.log(upload);
        // alert("File uploaded successfully.");

    } catch (error) {
        console.error(error);
        // alert("Error uploading file: " + error.message); // Inform user about the error
    }
};

export async function uploadThumbnailOnS3(file, filename) {
    const s3 = new S3({
        params: { Bucket: S3_BUCKET },
        region: REGION,
    });

    const params = {
        Bucket: S3_BUCKET,
        Key: filename,
        Body: file,
    };

    try {
        const upload = await s3.putObject(params).promise();
        console.log(upload);
        // alert("File uploaded successfully.");
        return upload

    } catch (error) {
        console.error(error);
        // alert("Error uploading file: " + error.message); // Inform user about the error
        return null
    }
}



