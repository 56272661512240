import React, { useEffect, useState } from 'react'
import ContentDialogBox from '../../react_components/dialog_box/ContentDialogBox'
import { extractURL } from '../../backend-ajax/backend';
import {backendURL} from '../../backend-ajax/config';
import { Card } from '@mui/material';

function HelpComponent({
    lesson,
    open,
    onClose
}) {
    const [isOpen, setIsOpen] = useState(open)
    const [lessonData, setLessonData] = useState(lesson.data)

    // async function getLessonData() {
    //     try {
    //         console.log(window.location.href);
    //         const lessonId = extractURL("lesson_id");
    //         const studentId = extractURL("user_id");
    //         console.log(lessonId);
    //         if (!!lessonId) {
    //             const response = await fetch(`${backendURL}/blockly/get-lesson-details`, {
    //                 method: "POST",
    //                 headers: {
    //                     "Content-Type": "Application/json"
    //                 },
    //                 body: JSON.stringify({
    //                     lessonId,
    //                     studentId
    //                 })
    //             })
    //             const data = await response.json()
    //             if (!!data) {
    //                 console.log(data.data);
    //                 setLessonData(data.data)
    //             }
    //             console.log(data);
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }
    // useEffect(() => {
    //     getLessonData()
    // }, [open])
    return (
        <div>
            <ContentDialogBox
                DialogContainerStyle={{
                    borderRadius: "30px",
                    background: "#EE9322",
                    color: "#80000B",
                    fontweight: "bolder",
                    textAlign: "center"
                }}
                maxWidth='xs'
                isOpen={isOpen}
                onClose={() => { onClose(); setIsOpen(false) }}
                content={<>
                    <hr style={{ border: "1px solid #80000B" }} />
                    <h3>{
                            lessonData && lessonData.PlaygroundName
                        }</h3>
                    <hr style={{ border: "1px solid #80000B" }} />

                    <p>
                        {
                            lessonData && lessonData.PlaygroundDescription
                        }
                    </p>
                    <hr style={{ border: "1px solid #80000B" }} />

                    {
                        lessonData&&lessonData.instructions && lessonData.instructions.map((value, index) => {
                            return <div>
                                <h4 key={index} style={{
                                    padding: "10px",
                                    margin: "5px",
                                    textAlign:"left",
                                    display:"flex"
                                }}>
                                    <span>{index + 1}. </span> {value}
                                </h4>
                                <hr style={{ border: "1px solid #80000B" }} />
                            </div>
                        })
                    }
                </>}
                isTransition={true}
                transitionDirection="down"
            />
        </div>
    )
}




export default HelpComponent