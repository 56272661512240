const { backendURL, frontendURL } = require("./config");


function extractURL(match) {
    const url = window.location.href
    let params = url.split("?")
    if (params.length === 1) {
        return null
    }
    let parameters = params[1]
    let list_of_params = parameters.split("&")
    // console.log(list_of_params);
    let return_value = null
    list_of_params.forEach(element => {
        let temp = element.split("=")
        if (temp[0] == match) {
            return_value = temp[1]
        }
    });
    console.log(match, "->", return_value);
    return return_value
}


async function precheckUserID() {
    let cookie = document.cookie
    console.log(cookie);
    if (!!cookie) {
        console.log(cookie.split(";"));
        for (let index = 0; index < cookie.split(";").length; index++) {
            const element = cookie.split(";")[index];
            console.log(element.split("="));
            let temp = element.split("=")
            if (temp[0].trim() == 'userID') {
                console.log("userID", temp[1].trim());
                console.log("setting user");
                try {
                    // console.log(window.location.href);
                    const userId = temp[1].trim();
                    // console.log(userId);
                    if (!!userId) {
                        const response = await fetch(`${backendURL}/blockly/get-user-details`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "Application/json"
                            },
                            body: JSON.stringify({
                                userId
                            })
                        })
                        const data = await response.json()
                        if (!!data) {
                            const full_name = data.user.euName
                            localStorage.setItem("user", JSON.stringify(data))
                            if (!!full_name) {
                                document.getElementById("user_name").innerHTML = full_name
                            }
                        }
            
                        const response2 = await fetch(`${backendURL}/uploadPIImageRoute/getProfileImageByPath`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "Application/json"
                            },
                            body: JSON.stringify({
                                userID: userId
                            })
                        })
                        const profileURL = await response2.text()
                        console.log("profileURL", profileURL);
                        if (!!profileURL) {
                            localStorage.setItem("profileURL", profileURL)
                            document.getElementById('profile-image').src = profileURL
                            try {
                                const response10 = await fetch(profileURL)
                                if (response10.status === 200) {

                                } else {
                                    document.getElementById('profile-image').src = "https://ui-avatars.com/api/?name=" + data.user.euName
                                }
                            } catch (error) {
                                document.getElementById('profile-image').src = "https://ui-avatars.com/api/?name=" + data.user.euName
                                console.log(error);
                            }
                        }
                        else {
                            alert("User Id not found")
                            window.location.href = "https://educobot.com"
                            localStorage.clear()
                        }
                    }
                } catch (error) {
                    localStorage.clear()
                    console.log(error);
                }
            }
        }
    }
}




async function handleLogin(setUser, setLesson) {
    await precheckUserID()
    localStorage.clear()
    // console.log(backendURL);
    try {
        // console.log(window.location.href);
        const userId = extractURL("user_id");
        // console.log(userId);
        if (!!userId) {
            const response = await fetch(`${backendURL}/blockly/get-user-details`, {
                method: "POST",
                headers: {
                    "Content-Type": "Application/json"
                },
                body: JSON.stringify({
                    userId
                })
            })
            const data = await response.json()
            if (!!data) {
                setUser(data)
                const full_name = data.user.euName
                localStorage.setItem("user", JSON.stringify(data))
                if (!!full_name) {
                    document.getElementById("user_name").innerHTML = full_name
                }
            }

            const response2 = await fetch(`${backendURL}/uploadPIImageRoute/getProfileImageByPath`, {
                method: "POST",
                headers: {
                    "Content-Type": "Application/json"
                },
                body: JSON.stringify({
                    userID: userId
                })
            })
            const profileURL = await response2.text()
            console.log("profileURL", profileURL);
            if (!!profileURL) {
                localStorage.setItem("profileURL", profileURL)
                document.getElementById('profile-image').src = profileURL
                try {
                    const response10 = await fetch(profileURL)
                    if (response10.status === 200) {

                    } else {
                        document.getElementById('profile-image').src = "https://ui-avatars.com/api/?name=" + data.user.euName
                    }
                } catch (error) {
                    document.getElementById('profile-image').src = "https://ui-avatars.com/api/?name=" + data.user.euName
                    console.log(error);
                }
            }
            else {
                alert("User Id not found")
                window.location.href = "https://educobot.com"
                localStorage.clear()

            }
        }
    } catch (error) {
        localStorage.clear()
        console.log(error);
    }
    try {
        // console.log(window.location.href);
        const lessonId = extractURL("lesson_id");
        const userId = extractURL("user_id");

        // console.log(userId);
        if (!!userId && !!lessonId) {
            const response = await fetch(`${backendURL}/blockly/get-lesson-details`, {
                method: "POST",
                headers: {
                    "Content-Type": "Application/json"
                },
                body: JSON.stringify({
                    lessonId,
                    studentId: userId
                })
            })
            const data = await response.json()
            console.log(data);
            if (!!data && !!data.data) {
                setLesson(data)
                if (!!data.submission) {
                    window.location.href = frontendURL + "/?&user_id=" + userId + "&submission_id=" + data.submission._id
                } else {

                }
            }
        }
    } catch (error) {
        console.log(error);
    }
    try {
        // console.log(window.location.href);
        const submissionId = extractURL("submission_id");
        const userId = extractURL("user_id");

        // console.log(userId);
        if (!!userId && !!submissionId) {
            const response = await fetch(`${backendURL}/blockly/get-submission-details`, {
                method: "POST",
                headers: {
                    "Content-Type": "Application/json"
                },
                body: JSON.stringify({
                    submissionId,
                    studentId: userId
                })
            })
            const data = await response.json()
            console.log(data);
            if (!!data && !!data.data) {
                setLesson(data)
            }
        }
    } catch (error) {
        console.log(error);
    }
}

async function getLessonData() {
    try {
        // console.log(window.location.href);
        const lessonId = extractURL("lesson_id");
        const userId = extractURL("user_id");
        // console.log(userId);
        if (!!userId && !!lessonId) {
            const response = await fetch(`${backendURL}/blockly/get-lesson-details`, {
                method: "POST",
                headers: {
                    "Content-Type": "Application/json"
                },
                body: JSON.stringify({
                    lessonId,
                    userId
                })
            })
            const data = await response.json()
        }
    } catch (error) {
        console.log(error);
    }
}
module.exports = {
    handleLogin,
    extractURL,
    getLessonData
}