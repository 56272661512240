import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import VM from 'scratch-vm';

import backdropLibraryContent from '../lib/libraries/backdrops.json';
import backdropTags from '../lib/libraries/backdrop-tags';
import LibraryComponent from '../components/library/library.jsx';
import { costumeUpload } from '../lib/file-uploader.js';

const messages = defineMessages({
    libraryTitle: {
        defaultMessage: 'Choose a Backdrop',
        description: 'Heading for the backdrop library',
        id: 'gui.costumeLibrary.chooseABackdrop'
    }
});


class BackdropLibrary extends React.Component {
    constructor(props) {
        super(props);
        bindAll(this, [
            'handleItemSelect'
        ]);
    }
    handleNewBackdrop (backdrops_, shouldActivateTab = true) {
        const backdrops = Array.isArray(backdrops_) ? backdrops_ : [backdrops_];
        return Promise.all(backdrops.map(backdrop =>
            this.props.vm.addBackdrop(backdrop.md5, backdrop)
        )).then(() => {
            if (shouldActivateTab) {
                // return this.props.onActivateTab(COSTUMES_TAB_INDEX);
            }
        });
    }
    async handleItemSelect(item) {
        const storage = this.props.vm.runtime.storage;
        const vmBackdrop = {
            name: item.name,
            rotationCenterX: item.rotationCenterX,
            rotationCenterY: item.rotationCenterY,
            bitmapResolution: item.bitmapResolution,
            skinId: null
        };
        // console.log("here it is selectd", item);
        const response = await fetch(item.md5ext,{
            method:"GET"
        })
        const result = await response.arrayBuffer()
        // console.log("result", result);

        // console.log(this.props);  
        // Do not switch to stage, just add the backdrop
        // this.props.vm.addBackdrop(item.md5ext, vmBackdrop);
        costumeUpload(result, 'image/png', storage, vmCostumes => {
            // this.props.vm.setEditingTarget(this.props.id);
            // vmCostumes.forEach((costume, i) => {
            //     costume.name = `${fileName}${i ? i + 1 : ''}`;
            // });
            // console.log(this);
            this.handleNewBackdrop(vmCostumes).then(() => {
            //     if (fileIndex === fileCount - 1) {
            //     }
            });
        }, this.props.onCloseImporting);

    }

    render() {
        return (
            <LibraryComponent
                data={backdropLibraryContent}
                id="backdropLibrary"
                tags={backdropTags}
                title={this.props.intl.formatMessage(messages.libraryTitle)}
                onItemSelected={this.handleItemSelect}
                onRequestClose={this.props.onRequestClose}
            />
        );
    }
}

BackdropLibrary.propTypes = {
    intl: intlShape.isRequired,
    onRequestClose: PropTypes.func,
    vm: PropTypes.instanceOf(VM).isRequired
};

export default injectIntl(BackdropLibrary);
