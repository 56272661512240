import { defineMessages, injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import VM from 'scratch-vm';
import { Button as MUIButton } from '@mui/material';
import Box from '../box/box.jsx';
import Button from '../button/button.jsx';
import ToggleButtons from '../toggle-buttons/toggle-buttons.jsx';
import Controls from '../../containers/controls.jsx';
import { getStageDimensions } from '../../lib/screen-utils';
import { STAGE_SIZE_MODES } from '../../lib/layout-constants';

import fullScreenIcon from './icon--fullscreen.svg';
import largeStageIcon from './icon--large-stage.svg';
import smallStageIcon from './icon--small-stage.svg';
import unFullScreenIcon from './icon--unfullscreen.svg';

import scratchLogo from '../menu-bar/scratch-logo.svg';
import styles from './stage-header.css';

const messages = defineMessages({
    largeStageSizeMessage: {
        defaultMessage: 'Switch to large stage',
        description: 'Button to change stage size to large',
        id: 'gui.stageHeader.stageSizeLarge'
    },
    smallStageSizeMessage: {
        defaultMessage: 'Switch to small stage',
        description: 'Button to change stage size to small',
        id: 'gui.stageHeader.stageSizeSmall'
    },
    fullStageSizeMessage: {
        defaultMessage: 'Enter full screen mode',
        description: 'Button to change stage size to full screen',
        id: 'gui.stageHeader.stageSizeFull'
    },
    unFullStageSizeMessage: {
        defaultMessage: 'Exit full screen mode',
        description: 'Button to get out of full screen mode',
        id: 'gui.stageHeader.stageSizeUnFull'
    },
    fullscreenControl: {
        defaultMessage: 'Full Screen Control',
        description: 'Button to enter/exit full screen mode',
        id: 'gui.stageHeader.fullscreenControl'
    }
});

const StageHeaderComponent = function (props) {
    const [isEducobotScreen, setEducobotScreen] = useState(false)
    const {
        isFullScreen,
        isPlayerOnly,
        onKeyPress,
        onSetStageLarge,
        onSetStageSmall,
        onSetStageFull,
        onSetStageUnFull,
        showBranding,
        stageSizeMode,
        vm
    } = props;

    let header = null;

    if (isFullScreen) {
        const stageDimensions = getStageDimensions(null, true);
        const stageButton = showBranding ? (
            <div className={styles.embedScratchLogo}>
                <a
                    href="https://scratch.mit.edu"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <img
                        alt="Scratch"
                        src={scratchLogo}
                    />
                </a>
            </div>
        ) : (
            <div className={styles.unselectWrapper}>
                <Button
                    className={styles.stageButton}
                    onLoad={() => {
                        if (isEducobotScreen) {
                            document.getElementById("canvas-renderer").classList.add("fullScreenCanvasEducobot")
                            document.getElementById("canvas-renderer").classList.remove("smallScreenCanvasEducobot")
                            document.getElementById("canvas-renderer").classList.remove("mediumScreenCanvasEducobot")
                        }
                    }}
                    onClick={() => {
                        onSetStageUnFull()
                    }}
                    onKeyPress={onKeyPress}
                >
                    <img
                        alt={props.intl.formatMessage(messages.unFullStageSizeMessage)}
                        className={styles.stageButtonIcon}
                        draggable={false}
                        src={unFullScreenIcon}
                        title={props.intl.formatMessage(messages.fullscreenControl)}
                    />
                </Button>
            </div>
        );
        header = (
            <Box className={styles.stageHeaderWrapperOverlay}>
                <Box
                    className={styles.stageMenuWrapper}
                    style={{ width: stageDimensions.width }}
                >
                    <Controls vm={vm} />
                    {stageButton}
                </Box>
            </Box>
        );
    } else {
        const stageControls =
            isPlayerOnly ? (
                []
            ) : (
                <div className={styles.stageSizeToggleGroup}>
                    <ToggleButtons
                        buttons={[
                            {
                                handleClick: () => {
                                    onSetStageSmall()
                                    if (isEducobotScreen) {
                                        document.getElementById("canvas-renderer").classList.remove("mediumScreenCanvasEducobot")
                                        document.getElementById("canvas-renderer").classList.add("smallScreenCanvasEducobot")
                                    } else {
                                        document.getElementById("canvas-renderer").classList.remove("smallScreenCanvasEducobot")
                                    }
                                },
                                icon: smallStageIcon,
                                iconClassName: styles.stageButtonIcon,
                                isSelected: stageSizeMode === STAGE_SIZE_MODES.small,
                                title: props.intl.formatMessage(messages.smallStageSizeMessage)
                            },
                            {
                                handleClick: () => {
                                    onSetStageLarge()
                                    if (isEducobotScreen) {
                                        document.getElementById("canvas-renderer").classList.remove("smallScreenCanvasEducobot")
                                        document.getElementById("canvas-renderer").classList.add("mediumScreenCanvasEducobot")
                                    } else {
                                        document.getElementById("canvas-renderer").classList.remove("mediumScreenCanvasEducobot")
                                    }
                                },
                                icon: largeStageIcon,
                                iconClassName: styles.stageButtonIcon,
                                isSelected: stageSizeMode === STAGE_SIZE_MODES.large,
                                title: props.intl.formatMessage(messages.largeStageSizeMessage)
                            }
                        ]}
                    />
                </div>
            );
        header = (
            <Box className={styles.stageHeaderWrapper}>
                <Box className={styles.stageMenuWrapper}>
                    <Controls vm={vm} />
                    <div className={styles.stageSizeRow}>
                        {stageControls}
                        <div style={{
                            display: "flex"
                        }}>
                            <Button
                                className={styles.stageButton}
                                onClick={() => {
                                    onSetStageFull();
                                    console.log(isEducobotScreen);
                                }}
                                onLoad={() => {
                                    document.getElementById("canvas-renderer").classList.remove("mediumScreenCanvasEducobot")
                                    document.getElementById("canvas-renderer").classList.remove("smallScreenCanvasEducobot")
                                    document.getElementById("canvas-renderer").classList.remove("fullScreenCanvasEducobot")

                                    const classValue = stageSizeMode === STAGE_SIZE_MODES.large ? "mediumScreenCanvasEducobot" : "smallScreenCanvasEducobot"
                                    if (isEducobotScreen) {
                                        document.getElementById("canvas-renderer").classList.add(classValue)
                                    } else {
                                        document.getElementById("canvas-renderer").classList.remove(classValue)
                                    }
                                }}
                            >
                                <img
                                    alt={props.intl.formatMessage(messages.fullStageSizeMessage)}
                                    className={styles.stageButtonIcon}
                                    draggable={false}
                                    src={fullScreenIcon}
                                    title={props.intl.formatMessage(messages.fullscreenControl)}
                                />
                            </Button>
                            <MUIButton onClick={() => {
                                document.getElementById("canvas-renderer").classList.remove("mediumScreenCanvasEducobot")
                                document.getElementById("canvas-renderer").classList.remove("smallScreenCanvasEducobot")

                                const classValue = stageSizeMode === STAGE_SIZE_MODES.large ? "mediumScreenCanvasEducobot" : "smallScreenCanvasEducobot"
                                if (!isEducobotScreen) {
                                    document.getElementById("canvas-renderer").classList.add(classValue)
                                } else {
                                    document.getElementById("canvas-renderer").classList.remove(classValue)
                                }
                                setEducobotScreen(!isEducobotScreen)
                            }} sx={{
                                padding:0
                            }}>
                                {
                                    !isEducobotScreen ? <>
                                        <h3 style={{
                                            margin:0,
                                            padding:0
                                        }}>4:3</h3>
                                    </> : <>
                                        <h3 style={{
                                            margin:0,
                                            padding:0
                                        }}>16:9</h3>
                                    </>
                                }
                            </MUIButton>
                        </div>
                    </div>
                </Box>
            </Box>
        );
    }

    return header;
};

const mapStateToProps = state => ({
    // This is the button's mode, as opposed to the actual current state
    stageSizeMode: state.scratchGui.stageSize.stageSize
});

StageHeaderComponent.propTypes = {
    intl: intlShape,
    isFullScreen: PropTypes.bool.isRequired,
    isPlayerOnly: PropTypes.bool.isRequired,
    onKeyPress: PropTypes.func.isRequired,
    onSetStageFull: PropTypes.func.isRequired,
    onSetStageLarge: PropTypes.func.isRequired,
    onSetStageSmall: PropTypes.func.isRequired,
    onSetStageUnFull: PropTypes.func.isRequired,
    showBranding: PropTypes.bool.isRequired,
    stageSizeMode: PropTypes.oneOf(Object.keys(STAGE_SIZE_MODES)),
    vm: PropTypes.instanceOf(VM).isRequired
};

StageHeaderComponent.defaultProps = {
    stageSizeMode: STAGE_SIZE_MODES.large
};

export default injectIntl(connect(
    mapStateToProps
)(StageHeaderComponent));
