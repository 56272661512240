import replacement_mapping from "./block_names_resplacement_mapping"

const mapping_keys=[...Object.keys(replacement_mapping)]
// console.log(mapping_keys);


export function handleReplacements(){
    let list_of_all_blockly_texts=document.getElementsByClassName("blocklyText")
    // console.log(list_of_all_blockly_texts);
    // list_of_all_blockly_texts[1].innerHTML="jump"
    // console.log(mapping_keys);
    for (let index = 0; index < list_of_all_blockly_texts.length; index++) {
        const element = list_of_all_blockly_texts[index];
        if(mapping_keys.includes(element.innerHTML.trim())){
            console.log("matched",element.innerHTML.trim());
            element.innerHTML=replacement_mapping[element.innerHTML.trim()]
        }
    }
}

setInterval(()=>{
    handleReplacements()
},500)
