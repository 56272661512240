import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ContentDialogBox from '../../react_components/dialog_box/ContentDialogBox'
import {backendURL} from '../../backend-ajax/config'
import Coin from './100-coin.png'
import Coin0 from './0-coin.png'



function CoinAssignComponent({ lesson }) {
    const [isDialogOpoen, setIsDialogOpen] = useState(false)
    const [studentData, setStudentData] = useState(null)
    const [coins, setCoins] = useState(lesson.submission.coins || 0)
    const [isSubmitted, setIsSubmitted] = useState(false)
    async function getStudentData() {
        try {
            console.log(lesson.submission.studentId);
            const response = await fetch(`${backendURL}/blockly/get-user-details`, {
                method: "POST",
                headers: {
                    "Content-Type": "Application/json"
                },
                body: JSON.stringify({
                    userId: lesson.submission.studentId
                })
            })
            const data = await response.json()
            if (!!data) {
                console.log(data);
                setStudentData(data)
            }
            console.log(data);
        } catch (error) {
            console.log(error);
        }
    }

    async function assignCoins() {
        try {
            const response = await fetch(`${backendURL}/blockly/AssignCoinByTeacherAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "Application/json"
                },
                body: JSON.stringify({
                    lessonId: lesson.data._id,
                    studentId: studentData.user.euUserId,
                    coins: coins,
                    submissionId: lesson.submission._id 
                })
            })
            const data = await response.json()
            if (!!data) {
                console.log(data);
                alert("Assigned Successfully")
                setIsDialogOpen(false)
            }
            console.log(data);
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        console.log(lesson);
        getStudentData()
    }, [lesson])
    return (
        <>
            <Button variant='contained' style={{
                color: "white"
            }} onClick={() => {
                setIsDialogOpen(true)
            }}>Assign Coins</Button>
            <ContentDialogBox
                DialogContainerStyle={{
                    borderRadius: "10px",
                    background: "#0E9322",
                    color: "white",
                    fontweight: "bolder",
                    textAlign: "center"
                }}
                maxWidth='xs'
                isOpen={isDialogOpoen}
                onClose={() => { setIsDialogOpen(false) }}
                content={<>
                    <h1>Assign Coins</h1>
                    <hr style={{ border: "1px solid white" }} />
                    <div style={{
                        textAlign: "left"
                    }}>

                        {
                            studentData && studentData.user && <>
                                <p>
                                    Name: {studentData.user.euName}
                                </p>
                                <p>
                                    Class: {studentData.user.euClass}
                                </p>
                                <p>
                                    Division: {studentData.student.sdDiv}
                                </p>
                            </>
                        }
                    </div>
                    <hr style={{ border: "1px solid white" }} />
                    {
                        lesson && lesson.submission && <>
                            <p>
                                Lesson Name: {lesson.data.PlaygroundName}
                            </p>
                        </>
                    }
                    <hr style={{ border: "1px solid white" }} />
                    {
                        lesson && lesson.submission && <>

                            <p>
                                Submitted on: {lesson.submission.createdAt}
                            </p>
                            <p>
                                Lesson Description: {lesson.data.updatedAt}
                            </p>

                        </>
                    }
                    <hr style={{ border: "1px solid white" }} />
                    {
                        lesson.submission.coins > 0 ? <p> You have assigned {lesson.submission.coins} coins, previously </p> : <p>Assign Coin to {studentData && studentData.user && studentData.user.euName}</p>
                    }
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                        {
                            [0, 0, 0].map((item, index) => {
                                return index < coins ? <div key={index}>
                                    <img src={Coin} alt="" style={{
                                        cursor: "pointer",
                                        width: "50px",
                                        height: "50px",
                                        margin: "10px",
                                    }} onClick={() => {
                                        setCoins(index + 1)
                                    }} />
                                </div> : <div key={index}>
                                    <img src={Coin0} alt="" style={{
                                        cursor: "pointer",
                                        width: "50px",
                                        height: "50px",
                                        margin: "10px",
                                    }} onClick={() => {
                                        setCoins(index + 1)
                                    }} />
                                </div>
                            })
                        }

                    </div>
                    <div>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => { assignCoins() }}
                        >
                            Submit
                        </Button>
                    </div>
                </>}
                isTransition={true}
                transitionDirection="down"
            />
        </>
    )
}

export default CoinAssignComponent