import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import VM from 'scratch-vm';

import costumeLibraryContent from '../lib/libraries/costumes.json';
import spriteTags from '../lib/libraries/sprite-tags';
import LibraryComponent from '../components/library/library.jsx';
import { costumeUpload, spriteUpload } from '../lib/file-uploader.js';
import { extractExtenion } from './sprite-library.jsx';

const messages = defineMessages({
    libraryTitle: {
        defaultMessage: 'Choose a Costume',
        description: 'Heading for the costume library',
        id: 'gui.costumeLibrary.chooseACostume'
    }
});


class CostumeLibrary extends React.PureComponent {
    constructor(props) {
        super(props);
        bindAll(this, [
            'handleItemSelected'
        ]);
    }
    handleNewSprite(spriteJSONString) {
        return this.props.vm.addSprite(spriteJSONString)

    }
    handleNewCostume(costume, fromCostumeLibrary, targetId) {
        const costumes = Array.isArray(costume) ? costume : [costume];

        return Promise.all(costumes.map(c => {
            // console.log(c);
            return this.props.vm.addCostumeFromLibrary(c.md5, c);
        }));
    }
    async handleItemSelected(item) {
        const vmCostume = {
            name: item.name,
            rotationCenterX: item.rotationCenterX,
            rotationCenterY: item.rotationCenterY,
            bitmapResolution: item.bitmapResolution,
            skinId: null
        };
        if (item.md5ext.includes("nehanaikdhure-educobot.github.io")) {
            const response = await fetch(item.md5ext, {
                method: "GET"
            })
            const result = await response.arrayBuffer()
            // console.log("result", result);
            const storage = this.props.vm.runtime.storage;
            const targetId = this.props.vm.editingTarget.id;
            const fileName = item.name;
            costumeUpload(result, "image/svg+xml", storage, vmCostumes => {
                // console.log("vmCostumes", vmCostumes);
                vmCostumes.forEach((costume, i) => {
                    costume.name = `${fileName}${i ? i + 1 : ''}`;
                });
                this.handleNewCostume(vmCostumes, true, targetId).then(() => {
                });
            });

        } else {

            const md5ext = item.md5ext.replace("/get", "").replace("https://cdn.assets.scratch.mit.edu/internalapi/asset/", "");
            this.props.vm.addCostumeFromLibrary(md5ext, vmCostume);
        }
    }

    render() {
        return (
            <LibraryComponent
                data={costumeLibraryContent}
                id="costumeLibrary"
                tags={spriteTags}
                title={this.props.intl.formatMessage(messages.libraryTitle)}
                onItemSelected={this.handleItemSelected}
                onRequestClose={this.props.onRequestClose}
            />
        );
    }
}

CostumeLibrary.propTypes = {
    intl: intlShape.isRequired,
    onRequestClose: PropTypes.func,
    vm: PropTypes.instanceOf(VM).isRequired
};

export default injectIntl(CostumeLibrary);
