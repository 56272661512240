import React from 'react'
import ContentDialogBox from '../../react_components/dialog_box/ContentDialogBox';
import { Button } from '@mui/material';
import Coin from './100-coin.png'
import Coin0 from './0-coin.png'
import { extractURL } from '../../backend-ajax/backend';
import { frontendURL } from '../../backend-ajax/config';
function SubmitSuccess({ data, title, subtitle }) {
    const [isOpen, setIsOpen] = React.useState(true);
    return (
        <div>
            <ContentDialogBox
                DialogContainerStyle={{
                    borderRadius: "30px",
                    background: "#059212",
                    color: "white",
                    fontweight: "bolder",
                    textAlign: "center"
                }}
                maxWidth='sm'
                isOpen={isOpen}
                onClose={() => { setIsOpen(false) }}
                content={<>
                    <hr style={{ border: "1px solid white" }} />
                    <h3>{title}</h3>
                    <hr style={{ border: "1px solid white" }} />
                    <img src="https://webapp.educobot.com/img/Int_to_Coding_Imgs/Option%202.png" alt="" style={{
                        width: "200px",
                    }} />
                    <hr style={{ border: "1px solid white" }} />
                    <p>{subtitle}</p>
                    <hr style={{ border: "1px solid white" }} />
                    {
                        data && data.isReviewed && data.coins ? <>
                            {
                                <p> You have got {data.coins} coins </p>
                            }
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                                {
                                    [0, 0, 0].map((item, index) => {
                                        return index < data.coins ? <div key={index}>
                                            <img src={Coin} alt="" style={{
                                                cursor: "pointer",
                                                width: "50px",
                                                height: "50px",
                                                margin: "10px",
                                            }} />
                                        </div> : <div key={index}>
                                            <img src={Coin0} alt="" style={{
                                                cursor: "pointer",
                                                width: "50px",
                                                height: "50px",
                                                margin: "10px",
                                            }} />
                                        </div>
                                    })
                                }

                            </div>
                        </> : <>

                            <h3 style={{
                                color: "yellow"
                            }}>Your Submission is in review, your teacher will assign coins based on your perfomance</h3>

                        </>
                    }
                    <div>
                        <Button variant='contained' style={{ margin: "10px 20px", }} onClick={() => {
                            const user = JSON.parse(localStorage.getItem('user'));
                            if (user) {
                                if (user.user.euUserType.toLowerCase() === "teacher") {
                                    window.location.href =  frontendURL+"/dashboard/teacher/blockly/";
                                } else if (user.user.euUserType.toLowerCase() === "student") {
                                    window.location.href = frontendURL+"/dashboard/student/blockly/";
                                } else if (user.user.euUserType.toLowerCase() === "saas") {
                                    window.location.href = frontendURL+"/dashboard/saas/blockly/";
                                }
                            }
                        }} >
                            Go To Dashboard
                        </Button>
                        <Button variant='contained' onClick={() => {
                            const lesson_id = extractURL("lesson_id");
                            if (!!lesson_id) {
                                window.location.href = "https://editor.blockly.educobot.com/?&user_id=" + data.studentId + "&submission_id=" + data._id
                            } else { 
                                setIsOpen(false)
                            }
                        }} style={{ margin: "10px 20px", }} >
                            See Your Submission
                        </Button>
                    </div>
                </>}
                isTransition={true}
                transitionDirection="up"
            />

        </div>
    )
}

export default SubmitSuccess