import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages, injectIntl, intlShape } from 'react-intl';

import LibraryItem from '../../containers/library-item.jsx';
import Modal from '../../containers/modal.jsx';
import Divider from '../divider/divider.jsx';
import Filter from '../filter/filter.jsx';
import TagButton from '../../containers/tag-button.jsx';
import Spinner from '../spinner/spinner.jsx';

import styles from './library.css';

const messages = defineMessages({
    filterPlaceholder: {
        id: 'gui.library.filterPlaceholder',
        defaultMessage: 'Search',
        description: 'Placeholder text for library search field'
    },
    allTag: {
        id: 'gui.library.allTag',
        defaultMessage: 'All',
        description: 'Label for library tag to revert to all items after filtering by tag.'
    }
});

const ALL_TAG = { tag: 'all', intlLabel: messages.allTag };
const tagListPrefix = [ALL_TAG];

class LibraryComponent extends React.Component {
    constructor(props) {
        console.log(ALL_TAG);
        super(props);
        bindAll(this, [
            'handleClose',
            'handleFilterChange',
            'handleFilterClear',
            'handleMouseEnter',
            'handleMouseLeave',
            'handlePlayingEnd',
            'handleSelect',
            'handleTagClick',
            'setFilteredDataRef'
        ]);
        this.state = {
            playingItem: null,
            filterQuery: '',
            selectedTag: ALL_TAG.tag,
            loaded: false,
            data: this.props.data
        };
    }
    componentDidMount() {
        // Allow the spinner to display before loading the content
        setTimeout(() => {
            this.setState({ loaded: true });
        });
        if (this.props.setStopHandler) this.props.setStopHandler(this.handlePlayingEnd);
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.filterQuery !== this.state.filterQuery ||
            prevState.selectedTag !== this.state.selectedTag) {
            this.scrollToTop();
        }
    }
    handleSelect(id) {
        this.handleClose();
        console.log(id);
        console.log(this.getFilteredData());
        console.log(this.getFilteredData()[id]);
        this.props.onItemSelected(this.getFilteredData()[id]);
    }
    handleClose() {
        this.props.onRequestClose();
    }
    handleTagClick(tag) {

        console.log(tag);
        this.setState((preState) => {
            return {

                ...preState,
                data: []
            }
        })
        const tempdata = this.getFilteredData()
        this.setState((preState) => {
            return {
                ...preState,
                data: tempdata
            }
        })
        if (this.state.playingItem === null) {
            this.setState({
                filterQuery: '',
                selectedTag: tag.toLowerCase()
            });
        } else {
            this.props.onItemMouseLeave(this.getFilteredData()[[this.state.playingItem]]);
            this.setState({
                filterQuery: '',
                playingItem: null,
                selectedTag: tag.toLowerCase()
            });
        }
    }
    handleMouseEnter(id) {
        // don't restart if mouse over already playing item
        if (this.props.onItemMouseEnter && this.state.playingItem !== id) {
            this.props.onItemMouseEnter(this.getFilteredData()[id]);
            this.setState({
                playingItem: id
            });
        }
    }
    handleMouseLeave(id) {
        if (this.props.onItemMouseLeave) {
            this.props.onItemMouseLeave(this.getFilteredData()[id]);
            this.setState({
                playingItem: null
            });
        }
    }
    handlePlayingEnd() {
        if (this.state.playingItem !== null) {
            this.setState({
                playingItem: null
            });
        }
    }
    handleFilterChange(event) {
        if (this.state.playingItem === null) {
            this.setState({
                filterQuery: event.target.value,
                selectedTag: ALL_TAG.tag
            });
        } else {
            this.props.onItemMouseLeave(this.getFilteredData()[[this.state.playingItem]]);
            this.setState({
                filterQuery: event.target.value,
                playingItem: null,
                selectedTag: ALL_TAG.tag
            });
        }
    }
    handleFilterClear() {
        this.setState({ filterQuery: '' });
    }
    getFilteredData() {
        if (this.state.selectedTag === 'all') {
            console.log(!this.state.filterQuery);
            if (!this.state.filterQuery) return this.props.data;
            return this.props.data.filter(dataItem => (
                (dataItem.tags || [])
                    // Second argument to map sets `this`
                    .map(String.prototype.toLowerCase.call, String.prototype.toLowerCase)
                    .concat(dataItem.name ?
                        (typeof dataItem.name === 'string' ?
                            // Use the name if it is a string, else use formatMessage to get the translated name
                            dataItem.name : this.props.intl.formatMessage(dataItem.name.props)
                        ).toLowerCase() :
                        null)
                    .join('\n') // unlikely to partially match newlines
                    .indexOf(this.state.filterQuery.toLowerCase()) !== -1
            ));
        }
        const fltered_data = this.props.data.filter(dataItem => (dataItem.tags && dataItem.tags.filter(value => value.toLowerCase() === this.state.selectedTag).length > 0));
        console.log(fltered_data);
        return fltered_data
    }
    scrollToTop() {
        this.filteredDataRef.scrollTop = 0;
    }

    setFilteredDataRef(ref) {
        this.filteredDataRef = ref;
    }
    render() {
        return (
            <Modal
                fullScreen
                contentLabel={this.props.title}
                id={this.props.id}
                onRequestClose={this.handleClose}
            >
                <div style={{
                    display: 'flex',
                }}>
                    {(this.props.filterable || this.props.tags) && (
                        <div className={styles.filterBar}>
                            <div style={{
                                padding: "10px"
                            }}>

                                {this.props.filterable && (
                                    <Filter
                                        className={classNames(
                                            styles.filterBarItem,
                                            styles.filter
                                        )}
                                        style={{
                                            margin: "10px"
                                        }}
                                        filterQuery={this.state.filterQuery}
                                        inputClassName={styles.filterInput}
                                        placeholderText={this.props.intl.formatMessage(messages.filterPlaceholder)}
                                        onChange={this.handleFilterChange}
                                        onClear={this.handleFilterClear}
                                    />
                                )}

                            </div>
                            {this.props.tags &&
                                <div className={styles.tagWrapper} style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: "column",
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    minHeight: '85vh',
                                }}>
                                    {tagListPrefix.concat(this.props.tags).map((tagProps, id) => (
                                        <TagButton
                                            active={this.state.selectedTag === tagProps.tag.toLowerCase()}
                                            className={classNames(
                                                styles.filterBarItem,
                                                styles.tagButton,
                                                tagProps.className
                                            )}
                                            style={{
                                                width: '150px',
                                                margin: "10px",
                                                display: "flex",
                                                justifyContent: "center"
                                            }}

                                            key={`tag-button-${id}`}
                                            onClick={this.handleTagClick}
                                            {...tagProps}
                                        />
                                    ))}
                                </div>
                            }
                        </div>
                    )}
                    <div style={{
                        height: "95vh"
                    }}>

                        <div
                            className={classNames(styles.libraryScrollGrid, {
                                [styles.withFilterBar]: this.props.filterable || this.props.tags
                            })}
                            ref={this.setFilteredDataRef}
                            key={JSON.stringify(this.state.data[0])}
                        >
                            {this.state.selectedTag && this.state.data && this.state.loaded ? this.state.data.map((dataItem, index) => (
                                <LibraryItem
                                    bluetoothRequired={dataItem.bluetoothRequired}
                                    collaborator={dataItem.collaborator}
                                    description={dataItem.description}
                                    disabled={dataItem.disabled}
                                    extensionId={dataItem.extensionId}
                                    featured={dataItem.featured}
                                    hidden={dataItem.hidden}
                                    iconMd5={dataItem.costumes ? dataItem.costumes[0].md5ext : dataItem.md5ext}
                                    iconRawURL={dataItem.rawURL}
                                    icons={dataItem.costumes}
                                    id={index}
                                    insetIconURL={dataItem.insetIconURL}
                                    internetConnectionRequired={dataItem.internetConnectionRequired}
                                    isPlaying={this.state.playingItem === index}
                                    key={index}
                                    name={dataItem.name}
                                    showPlayButton={this.props.showPlayButton}
                                    onMouseEnter={this.handleMouseEnter}
                                    onMouseLeave={this.handleMouseLeave}
                                    onSelect={this.handleSelect}
                                />
                            )) : (
                                <div className={styles.spinnerWrapper}>
                                    <Spinner
                                        large
                                        level="primary"
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                </div>

            </Modal>
        );
    }
}

LibraryComponent.propTypes = {
    data: PropTypes.arrayOf(
        /* eslint-disable react/no-unused-prop-types, lines-around-comment */
        // An item in the library
        PropTypes.shape({
            // @todo remove md5/rawURL prop from library, refactor to use storage
            md5: PropTypes.string,
            name: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.node
            ]),
            rawURL: PropTypes.string
        })
        /* eslint-enable react/no-unused-prop-types, lines-around-comment */
    ),
    filterable: PropTypes.bool,
    id: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
    onItemMouseEnter: PropTypes.func,
    onItemMouseLeave: PropTypes.func,
    onItemSelected: PropTypes.func,
    onRequestClose: PropTypes.func,
    setStopHandler: PropTypes.func,
    showPlayButton: PropTypes.bool,
    tags: PropTypes.arrayOf(PropTypes.shape(TagButton.propTypes)),
    title: PropTypes.string.isRequired
};

LibraryComponent.defaultProps = {
    filterable: true,
    showPlayButton: false
};

export default injectIntl(LibraryComponent);
